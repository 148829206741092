//API URl
export const BASE_URL = `${process.env.REACT_APP_ASSETS_API_URL}`;
export const DEVICE_BASE_URL = `${process.env.REACT_APP_DEVICE_API_URL}`;
export const FILE_BASE_URL = `${process.env.REACT_APP_FILE_API_URL}`;
export const KEYCLOAK_BASE_URL = `${process.env.REACT_APP_KEYCLOAK_API_URL}`;
export const ASSET_SUITE_LOGIN_URL = `${process.env.REACT_APP_SUITE_URL}`;

export const ASSET_URLS = {
  ASSET_SUITE_URL: `${process.env.REACT_APP_SUITE_URL}`,
  ASSET_MANAGEMENT_URL: `${process.env.REACT_APP_ASSETS_URL}`,
  ASSET_MODELS_URL: `${process.env.REACT_APP_MODELS_URL}`,
  DEVICE_MANAGEMENT_URL: `${process.env.REACT_APP_DEVICE_MANAGEMENT_URL}#/devices`,
  FIELD_SERVICE_MANAGEMENT_URL: `${process.env.REACT_APP_FSM_URL}`,
  OPERATIONAL_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
  MANAGEMENT_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
};

export const ASSET_UMS_API = {
  GET_LOGIN_TOKEN: KEYCLOAK_BASE_URL+"realms/seqrops/protocol/openid-connect/token",
  VERIFY_LOGIN_TOKEN: KEYCLOAK_BASE_URL+"realms/seqrops/protocol/openid-connect/token/introspect",
  LOGOUT_TOKEN: KEYCLOAK_BASE_URL+"realms/seqrops/protocol/openid-connect/logout",
};

export const SESSION_KEY = {
  NAVIGATION_STATE: "vdfwfznVgu",
  LOCAL_STORAGE_KEY: "ut",
  REDIRECT_LOCATION_KEY: 'rl',
  CLEAR_ALL_TOKEN_DATA_KEY: 'cl',
  SUITE_KEY: "su1te",
  MODELS_KEY: "mod3ls",
  ASSETS_KEY: "ass3ts",
  DEVICE_MANAGEMENET_KEY: "d3vice",
  FSM_KEY:"f2m",
  OPERATIONAL_DASHBOARD_KEY:"0pd",
  MANAGEMENT_DASHBOARD_KEY:"Wgmt"
};

export const SESSION_TYPE = {
  USER_LOGIN_TYPE : "1",
  USER_LOGOUT_TYPE : "0",
  DOMAIN_PASS_TYPE : "2",
}

export const IFRAME_ID = {
  SUITE_IFRAME_ID: "ifr_suite",
  MODELS_IFRAME_ID: "ifr_models",
  ASSETS_IFRAME_ID: "ifr_assets",
  DEVICE_MANAGEMENET_IFRAME_ID: "ifr_device",
  FSM_IFRAME_ID: "ifr_fsm",
  DASHBOARD_IFRAME_ID: "ifr_dashboard",
}

//Asset resource scopes (keycloak)
export const ASSET_SCOPES = [
  "asset-management",
  "asset-models",
  "admin-console",
  "asset-suite",
  "field-service-management",
  "operational-dashboard",
  "management-dashboard",
];
export const ASSET_MANAGEMENT_SCOPE = "asset-management";
export const ASSET_SUITE_SCOPE = "asset-suite";
export const ASSET_MODELS_SCOPE = "asset-models";
export const ADMIN_CONSOLE_SCOPE = "admin-console";
export const FIELD_SERVICE_MANAGEMENT = "field-service-management";
export const MANAGEMENT_DASHBOARD = "operational-dashboard";
export const OPERATIONAL_DASHBOARD = "management-dashboard";

export const ASSET_MANAGEMENT_API = {
  // Management CRUD

  GET_ALL_MODELS: BASE_URL + "api/v1/instance/getAllModels/",
  CREATE_INSTANCE: BASE_URL + "api/v1/instance/create",
  GET_INSTANCES: BASE_URL + "api/v1/instance/getAllInstances/",
  GET_INSTANCE_DETAILS: BASE_URL + "api/v1/instance/details",
  SAVE_PROPERTY: BASE_URL + "api/v1/instance/save/properties",
  SAVE_FILE: FILE_BASE_URL + "api/v1/file-management/upload",
  SAVE_FILE_PROPERTIES: BASE_URL + "api/v1/instance/save/fileProperties",
  GET_INSTANCE_HIERARCHY: BASE_URL + "api/v1/instance/hierarchy",
  DOWNLOAD_FILE: FILE_BASE_URL + "api/v1/file-management/download/",
  GET_ALL_DEVICES: DEVICE_BASE_URL + "api/v1/device/multiplesearch",
  GET_ALL_ENUMS: DEVICE_BASE_URL + "api/v1/device/enums",
  SAVE_DYNAMIC_PROPERTY: BASE_URL + "api/v1/instance/save/dynamicProperty",
  GET_ASSOICIATIONS: BASE_URL + "api/v1/instance/getAssociations",
  CREATE_EDGE_RELATION: BASE_URL + "api/v1/instance/createAssosiation",
  PUBLISH_INSTANCE: BASE_URL + "api/v1/publish/submit-instance",
  DELETE_INSTANCE: BASE_URL + "api/v1/instance/delete-instance",
  REPLACE_FILE_PROPERTIES: BASE_URL + "api/v1/instance/update/fileProperties",
  REPLACE_FILE: FILE_BASE_URL + "api/v1/file-management/replace",

  UPLOAD_FILE_PROPERTY: FILE_BASE_URL + "api/v1/file-management/upload",
  REPLACE_FILE_PROPERTY: FILE_BASE_URL + "api/v1/file-management/replace",
  DELETE_FILE_PROPERTY: FILE_BASE_URL + "api/v1/file-management/delete/{0}",
};

export const KEYCLOAK_GRANT_TYPES = {
  PASSWORD: "password",
  CLIENT_CREDENTIALS: "client_credentials",
  REFRESH_TOKENS: "refresh_token",
};

export const KEYCLOAK_CLIENTS = { 
  ASSET_SUITE : 'asset-suite',
  ASSET_MANAGEMENT : 'asset-management',
  ASSET_MODELS : 'asset-models',
  DEVICE_MANAGEMENT : 'device-management',
  FIELD_SERVICE_MANAGEMENT : 'field-service-management',
  OPERATIONAL_DASHBOARD : 'operational-dashboard',
  MANAGEMENT_DASHBOARD : 'management-dashboard',
};

export const KEYCLOAK_CLIENT_SECRETS = {
  ASSET_SUITE: `${process.env.REACT_APP_SUITE_CLIENT_SECRET}`,
  ASSET_MANAGEMENT: "",
};

export const CLEAR_CLIENT_SCOPES = "clear-scopes";

export const MESSAGES = {
  SOMETHING_WENT_WRONG: "Something went wrong",
  LOGGED_OUT: "Logged out Successfully",
  USER_UNAUTHORIZED: "User unauthorized",

  /** Instance Messages */
  INSTANCE_NAME_EXISTS: "Name already exist",
  INSTANCE_NAME_REQUIRED: "Please enter name",
  INSTANCE_NAME_PATTERN: "Please enter a valid name",
  CONVENTION_NOT_MATCH: "Please enter a valid value",
  INSTANCE_DESC_REQUIRED: "Please enter description",
  INSTANCE_CREATE_SUCCESS: "Instance created",
  PROPERTY_UPDATED_SUCCESS: "Property updated",
  INSTANCE_CREATION_FAILED: "Instance creation failed",
  INSTANCE_DETAILS_RETREIVED:
    "Instance details has been retreived successfully",
  RELATION_MAX_REACHED: "Assosiation limit reached",
  INSTANCE_NOT_FOUND: "No instance found",
  REGEX_ERROR: "Regex not matching",
  MANDATORY_FIELD_REQUIRED: "Field is required",
  INVALID_PORT_NUMBER: "Invalid port number",
  NUMERIC_CONVENTION_ERROR: "Values except numerics are not allowed",
  SCHEMA_DB_NOT_FOUND_MESSAGE: "Published models not found",

  FILE_DELETED_SUCCESSFULLY: "File has been deleted succesfully",
  FILE_DELETED_FAILED: "File deletion failed",
  FILE_FORMAT_ERROR: "Unsupported file type",
  FILE_SIZE_EXCEED_ERROR: "File size limit exceeded ( Max Size 20 MB)",
  UNKNOWN_DATATYPE_ERROR: "Unknown datatype in database",
  FILE_SIZE_MIN_ERROR: "File size dosent support ( Min Size 5 MB)",
  FILE_SAVE_SUCCESS: "File save success",
  FILE_DOWNLOAD_SUCCESSFULLY: "File successfully downloaded",
  FILE_DOWNLOAD_FAILED: "File download failed",
  DEVICES_FETCHED_SUCCESS: "Devices fetched success",
  DYNAMIC_PROPERTY_SAVED: "Dynamic property updated",
  DYNAMIC_PROPERTY_ERROR: "Dynamic property update failed",
  EMPTY_STATIC_PROPERTY_ERROR: "No updated fileds found",
  EMPTY_DYNAMIC_PROPERTY_ERROR: "Empty request for updating property",
  ASSOSIATION_CREATED_SUCCESFULLY: "Assosiation created Successfully",
  ASSOSIATION_CREATED_FAILED: "Assosiation creation failed",
  INSTANCE_PROPERTY_UPDATED: "Property updated",
  INSTANCE_PROPERTY_UPDATE_FAILED: "Property updation failed",
  PUBLISH_SUCCESS: "Publish success",
  PUBLISH_FAILED: "Publish failed",
  EMPTY_INSTANCE_NAME_ERROR:
    "Hint : Avoid blank spaces and special characters except  ' _ '  ",
  EMPTY_ASSOCIATION_ERROR: "Please select an assosiation",
  INSTANCE_DELETE_SUCCESS: "Deleted successfully",
  INSTANCE_DELETE_FAILED: "Failed to delete ",
  DUPLICATE_ASSOCIATION_ERRORCODE: "DUPLICATE_ASSOCIATION",
  DUPLICATE_ASSOCIATION_MESSAGE: "Model already associated",
};

export const RESERVED_PROPS = {
  RESERVED_PROPERTY_DELETED: "deleted",
};

export const ERROR_CODES = {
  SCHEMA_DB_NOT_FOUND: "SCHEMA_DB_NOT_FOUND",
};
