import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInstancePropertySlice } from "./instance-properties.interface";

//initial state
const initialState: IInstancePropertySlice = {
  dynamicProperties: [],
  protocols:[],
};

const instancePropertySlice = createSlice({
  name: "instanceProperties",
  initialState: initialState,
  reducers: {
    setDynamicProperties: (state, action: PayloadAction<any>) => {
      state.dynamicProperties = [...action.payload];
    },
    setProtocols: (state, action: PayloadAction<any>) => {
      state.protocols = [...action.payload];
    },
  },
});

export const {setDynamicProperties, setProtocols } = instancePropertySlice.actions;
export default instancePropertySlice.reducer;
