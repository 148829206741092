import { Tooltip } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import { IModelTreeActions } from "../model-tree.types";
import NodeToggle from "./node-toggle";
import addIcon from "../../../assets/images/seqrops_add_btn.svg";
import deleteIcon from "../../../assets/images/seqrops_delete_icon.svg";
import DeleteConfirmationDialog from "components/dialogs/delete-confirmation.dialog";
import { IInstanceItemDetails } from "store/asset-instance/asset-instance.interface";
import { useEffect, useState } from "react";

function AddItem({ parent, actions, customKey }: any) {
  const [isHide, setIsHide] = useState(false)

  useEffect(() => {
    // if (parent.edges.length === Number(parent.max)) {
    //   console.log("hide button",parent);
    //   setIsHide(true);     
    // }
  }, [])
  return (
    <span style={{ marginLeft: 10 }}>
      <img
        src={addIcon}
        alt="Add"
        style={{ width: "1vw" }}
        onClick={() => {
          actions.assosiationToggle(
            true,
            parent.rid,
            parent.edgeName,
            parent.edges[0]?.vertexName || parent.instance?.target,
            parent.vertexName || parent.instance?.target,
            parent.isAssociation,
            customKey
          );
        }}
        hidden={parent.edges.length === Number(parent.max)}
      />

    </span>
  );
}

function DeleteItem({ parent, actions, customKey, onClick }: any) {
  return (
    <span style={{ marginLeft: 10 }}>
      <img
        src={deleteIcon}
        alt="Delete"
        style={{ width: "1vw" }}
        onClick={() => {
          onClick();
        }}
      />
    </span>
  );
}

function NodeActionMenu({
  item,
  isRootNode,
  actions,
  customKey,
}: {
  item: IInstanceItemDetails;
  isRootNode: boolean;
  actions: IModelTreeActions;
  customKey: string;
}) {
  return (
    <>
      <div className="seqrops-node-option-section d-inline-flex">
        {item.isEdge ? (
          <AddItem parent={item} actions={actions} customKey={customKey} />
        ) : null}

        {!item.isEdge || isRootNode &&
          item.instance?.["__INSTANCE_ROOT__"]?.count > item.min ? (
          <DeleteItem
            parent={item}
            actions={actions}
            customKey={customKey}
            onClick={() => {
              if (isRootNode) {
                console.log("item : ", item.instance.name);
                actions.deleteInstance(customKey, {
                  sourceId: item?.rid,
                  sourceModel: item.vertexName,
                  targetId: "__EMPTY_VALUE__",
                  targetModel: "__EMPTY_VALUE__",
                  edgeName: "__EMPTY_VALUE__",
                  isRootNode: isRootNode,
                  instanceName: item?.instance?.name
                });
              } else {
                console.log("item?.instance?.name : ", item?.instance?.name)
                actions.deleteInstance(customKey, {
                  sourceId: item.instance?.["__INSTANCE_ROOT__"]?.sourceId,
                  sourceModel: item.instance?.["__INSTANCE_ROOT__"]?.sourceModel,
                  targetId: item.instance?.["__INSTANCE_ROOT__"]?.targetId,
                  targetModel: item.instance?.["__INSTANCE_ROOT__"]?.targetModel,
                  edgeName: item.instance?.["__INSTANCE_ROOT__"]?.edgeName,
                  isRootNode: isRootNode,
                  instanceName: item?.instance?.name,
                });
              }

            }}
          />
        ) : null}

        {!item.isEdge ? (
          // <Tooltip title="options">
          //   <>
          //     <Dropdown className="node-options" drop="end">
          //       <Dropdown.Toggle
          //         className="removecaret"
          //         id="dropdown-autoclose-true"
          //         as={NodeToggle}
          //       ></Dropdown.Toggle>
          //       <Dropdown.Menu>
          //         {!item.isEdge ? (
          //           <>
          //             <Dropdown.Item
          //               onClick={() => {
          //                 actions.updateModel({
          //                   id: item.rid,
          //                   name: item.isEdge ? item.edgeName : item.vertexName,
          //                   isRootNode,
          //                 });
          //               }}
          //             >
          //               Edit
          //             </Dropdown.Item>
          //           </>
          //         ) : null}
          //       </Dropdown.Menu>
          //     </Dropdown>
          //   </>
          // </Tooltip>
          <></>
        ) : null}
      </div>
    </>
  );
}

export default NodeActionMenu;
