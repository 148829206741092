import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAssetInstanceSlice,
  IAssociationItem,
  IInstanceItemDetails,
  IInstanceItemProperty,
} from "./asset-instance.interface";

//initial state
const initialState: IAssetInstanceSlice = {
  instance: {
    rid: "",
    edgeName: "",
    vertexName: "",
    hasEdge: false,
    isEdge: false,
    isAssosiation: false,
    instance: {
      __ATTACHMENT__: [],
    },
    edges: [],
    max: 0,
    min: 0,
    properties: [],
  },
  rootInstance: {
    rid: "",
    edgeName: "",
    vertexName: "",
    hasEdge: false,
    isEdge: false,
    isAssosiation: false,
    instance: {
      __ATTACHMENT__: [],
    },
    edges: [],
    max: 0,
    min: 0,
    properties: [],
  },
  properties: {},
  instanceHierarchy: [],
  assosiations: [],
  instanceMap: {},
};

const assetInstanceSlice = createSlice({
  name: "assetInstances",
  initialState: initialState,
  reducers: {
    setInstance: (state, action: PayloadAction<IInstanceItemDetails>) => {
      if (action.payload != null) {
        state.instance = { ...action.payload };
        if (
          state.instance.rid === state.rootInstance.rid &&
          state.instance.isEdge === state.rootInstance.isEdge
        ) {
          state.rootInstance = { ...action.payload };
        }
        if (!state.rootInstance.isEdge) {
          state.instanceMap[state.instance.rid] = {
            ...state.instance.instance,
          };
        }
      }
    },
    setRootInstance: (state, action: PayloadAction<IInstanceItemDetails>) => {
      if (action.payload != null) {
        state.rootInstance = { ...action.payload };
        state.instance = { ...action.payload };
      }
    },
    setInstanceStaticProperties: (
      state,
      action: PayloadAction<{ [key: string]: IInstanceItemProperty[] }>
    ) => {
      state.properties = action.payload;
    },
    setInstanceHierarchy: (state, action: PayloadAction<any>) => {
      state.instanceHierarchy = action.payload;
    },
    setModelAssosiations: (
      state,
      action: PayloadAction<IAssociationItem[]>
    ) => {
      state.assosiations = action.payload;
    },
  },
});

export const {
  setInstance,
  setRootInstance,
  setInstanceHierarchy,
  setModelAssosiations,
  setInstanceStaticProperties,
} = assetInstanceSlice.actions;
export default assetInstanceSlice.reducer;
