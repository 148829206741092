import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "store";
import { assetInstanceActions } from "store/asset-instance/asset-instance.action";
import { setRootInstance } from "store/asset-instance/asset-instance.slice";
import { encryptUtils } from "../../utils/encryption.utils";
import PropertyConsole from "../property-console/property-console";
import TreeConsole from "../tree-console/tree-console";
import "./view-console.scss";

function ViewConsole() {
  const dispatch = useAppDispatch();
  const { details } = useParams();
  const [instance, setInstance] = useState<{
    instanceId: string;
    currentModel: string;
  }>({
    instanceId: "",
    currentModel: "",
  });
  useEffect(() => {
    async function fetchData(reqBody: { rid: string; modelName: string }) {
      const details = await assetInstanceActions.getInstanceDetails(reqBody);
      if (details) {
        dispatch(setRootInstance({ ...details.data.data[0], edges: [] }));
      }
    }
    const data = encryptUtils.decryptURL(details);
    if (data) {
      setInstance({
        instanceId: data.id,
        currentModel: data.modelName,
      });
      fetchData({
        rid: data.id,
        modelName: data.modelName,
      });
    }
  }, [details]);

  return (
    <>
      <div className="seqrops-instance-console-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <TreeConsole
                instanceId={instance.instanceId}
                modelName={instance.currentModel}
              />
            </div>
            <div className="col-md-5">
              <PropertyConsole />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewConsole;
