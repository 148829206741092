import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import "./create-association.scss";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import closeBtn from "../../../assets/images/seqrops_close_icon.svg";
import { useAppSelector } from "../../../store";
import { assetInstanceActions } from "../../../store/asset-instance/asset-instance.action";
import {
  createEdgeRelationPayload,
  createInstancePayload,
  EdgeRelationPayload,
  getAssosiationsPayload,
  IAssociationItem,
} from "../../../store/asset-instance/asset-instance.interface";
import { MESSAGES } from "../../../config/default.config";
import { seqrOpsActions } from "store/seqr-ops/seqr-ops.action";

function CreateAssosiation(props: {
  getSubModel: (id: string, key: string, model: string) => void;
  assosiationPayload: EdgeRelationPayload;
  isAssociation: boolean;
  assosiationPopUp: boolean;
  setAssosiationPopUp: (state: boolean) => void;
}) {
  const dispatch = useDispatch<any>();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    setError,
    formState,
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      associationTarget: "",
    },
  });
  const errors: any = formState.errors;
  const loading = useAppSelector((state) => state.seqrOps.loadingState.loading);

  const [AssociationEmptyFlag, setAssociationErrorFlag] =
    useState<boolean>(false);

  //fetching devices
  const assosiations = useAppSelector(
    (state) => state.assetInstances.assosiations
  );

  const errorWrap = (errorMsg: string) => {
    if (!errorMsg) return null;
    return <p className="error-message-section">{errorMsg}</p>;
  };

  const setSuccess = (state: boolean = true) => {
    if (state) {
      handleClose();
      dispatch(seqrOpsActions.setLoader(false));
      props.getSubModel(
        props.assosiationPayload?.id || "",
        props.assosiationPayload?.customKey.slice(
          0,
          props.assosiationPayload?.customKey.lastIndexOf("-")
        ),
        props?.assosiationPayload?.sourceModelName || ""
      );
    }else{
      dispatch(seqrOpsActions.setLoader(false));
    }
  };

  useEffect(() => {
    clearErrors();
    reset();
  }, [clearErrors, props.assosiationPopUp, reset]);

  //handlng save
  const handleSave = async (relationDetails: any) => {
    dispatch(seqrOpsActions.setLoader(true));
    if (!props.isAssociation) {
      let createInstancePayload: createInstancePayload = {
        modelName: props?.assosiationPayload?.vertexName,
        name: relationDetails.name,
        description: relationDetails.description,
        sourceModelId: props?.assosiationPayload?.id,
        sourceModelName: props?.assosiationPayload?.sourceModelName,
        edgeName: props?.assosiationPayload?.edgeName,
      };
      await assetInstanceActions.createInstance(
        createInstancePayload,
        setError,
        setSuccess
      );
    } else {
      if (
        relationDetails.associationTarget === "" ||
        relationDetails.associationTarget === undefined ||
        relationDetails.associationTarget === null
      ) {
        dispatch(seqrOpsActions.setLoader(false));
        setAssociationErrorFlag(true);
      } else {
        let createEdgeRelationPayload: createEdgeRelationPayload = {
          sourceName: props?.assosiationPayload?.sourceModelName,
          sourceId: props?.assosiationPayload?.id,
          edgeName: props?.assosiationPayload?.edgeName,
          targetId: relationDetails.associationTarget,
          targetName: props?.assosiationPayload?.vertexName,
        };

        dispatch(
          assetInstanceActions.createEdgeRelation(
            createEdgeRelationPayload,
            setError,
            setSuccess
          )
        );
      }
    }
  };

  //handling close
  const handleClose = () => {
    clearErrors();
    reset();
    setAssociationErrorFlag(false);

    props.setAssosiationPopUp(false);
  };

  useEffect(() => {
    let associationPayload: getAssosiationsPayload = {
      sourceId: "",
      sourceName: props?.assosiationPayload?.vertexName,
    };
    if (
      props?.assosiationPayload?.id !== "" &&
      props?.assosiationPayload?.id !== undefined
    ) {
      dispatch(assetInstanceActions.getAssosiations(associationPayload));
    }
  }, [dispatch, props.assosiationPayload]);

  return (
    <>
      <div className="seqrops create-assosiation-wrapper">
        <div className="container-fluid">
          <Modal
            show={props.assosiationPopUp}
            onHide={setSuccess}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
          >
            <Modal.Header>
              <Modal.Title>
                {"Create " + props?.assosiationPayload?.vertexName || ""}
                <div className="create_pagination">
                  <span>{props?.assosiationPayload?.sourceModelName}</span> /{" "}
                  <span>{props?.assosiationPayload?.edgeName}</span>
                </div>
              </Modal.Title>
              <button
                className="seqrops-relationship-popup-close-btn"
                disabled={loading}
                onClick={() => {
                  handleClose();
                }}
              >
                <img src={closeBtn} alt="" />
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="seqrops-create-instance-form-section">
                {props.isAssociation ? (
                  <>
                    <label className="form-label" htmlFor="name">
                      Association
                    </label>
                    <div className="seqrops-assosiation-dropdown-section">
                      <Autocomplete
                        id="seqrops-assosiation-selecting-dropdown"
                        disableClearable
                        options={assosiations}
                        {...register("associationTarget", {}) }
                        onChange={(e, data) => {
                          setValue("associationTarget", data.targetId);
                          clearErrors("associationTarget");
                          setAssociationErrorFlag(false);
                        }}
                        getOptionLabel={(option: IAssociationItem) =>
                          option.targetName
                        }
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => {
                          return (
                            <TextField {...params} key={params.id} label="" />
                          );
                        }}
                      />
                       <label className="form-label" htmlFor="ass">
                       {AssociationEmptyFlag && (
                        <>{errorWrap(MESSAGES.EMPTY_ASSOCIATION_ERROR)}</>
                      )}
                      </label>
                     
                    </div>
                    {errorWrap(errors?.associationTarget?.message)}
                  </>
                ) : (
                  <>
                    <div className="seqrops-create-instance-target-name-section">
                      <label className="form-label" htmlFor="name">
                        Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        maxLength={255}
                        autoFocus
                        {...register("name", {
                          required: MESSAGES.INSTANCE_NAME_REQUIRED,
                          pattern: {
                            value: /^\w*$/,
                            message: MESSAGES.INSTANCE_NAME_PATTERN,
                          },
                        })}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setValue("name", e.target.value.replace(/\W/g, ""));
                          clearErrors("name");
                        }}
                      />
                       <label className="form-label" htmlFor="name">
                       {errorWrap(errors?.name?.message)}
                      </label>
                      
                    </div>
                    <div className="seqrops-create-instance-description-section">
                      <label className="form-label" htmlFor="description">
                        Description
                      </label>
                      <textarea
                        className="form-control"
                        id="description"
                        maxLength={255}
                        rows={5}
                        {...register("description", {
                          required: MESSAGES.INSTANCE_DESC_REQUIRED,
                        })}
                        onChange={() => {
                          clearErrors("description");
                        }}
                      ></textarea>
                       <label className="form-label" htmlFor="description">
                       {errorWrap(errors?.description?.message)}
                      </label>
                     
                    </div>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button
                className="sequrops_btn_primary"
                onClick={handleSubmit(handleSave)}
                disabled={loading}
              >
                Cancel
              </Button> */}
              <Button
                className="sequrops_btn_primary"
                onClick={handleSubmit(handleSave)}
                disabled={loading}
              >
                Create
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default CreateAssosiation;
