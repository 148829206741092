import axios from "axios";
import { ASSET_MANAGEMENT_API } from "../../config/default.config";
import { getAllModelsPayload } from "./asset-model.interface";

const fetchAllModels = async (getAllModelsPayload: getAllModelsPayload) => {
  
    const response = await axios.post(ASSET_MANAGEMENT_API.GET_ALL_MODELS, getAllModelsPayload);
    return response.data;
  };

  export const assetModelService = { 
    fetchAllModels,
  };