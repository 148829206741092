import axios from "axios";
import { IInstanceDeleteEvent } from "components/model-tree.component/model-tree.types";
import { ASSET_MANAGEMENT_API } from "../../config/default.config";
import {
  IGetAllInstancePayload,
  createEdgeRelationPayload,
  createInstancePayload,
  getAssosiationsPayload,
  getInstanceHierarchyPayload,
  publishPayload,
} from "./asset-instance.interface";

const createInstance = async (instanceDetails: createInstancePayload) => {
  const response = await axios.post(
    ASSET_MANAGEMENT_API.CREATE_INSTANCE,
    instanceDetails, {
      validateStatus: function (status) {
        return status >= 200 && status < 600; // Treat only 2xx status codes as successful
      }
  });
  return response;
};

const getAllInstances = async (instancePayload: IGetAllInstancePayload) => {
  const response = await axios.post(
    ASSET_MANAGEMENT_API.GET_INSTANCES,
    instancePayload
  );
  return response.data;
};

const getInstanceDetails = async (insatanceId: any) => {
  const response = await axios.post(
    ASSET_MANAGEMENT_API.GET_INSTANCE_DETAILS,
    insatanceId
  );
  return response.data;
};

const fetchInstanceHierarchy = async (
  getInstanceHierarchyPayload: getInstanceHierarchyPayload
) => {
  const response = await axios.post(
    ASSET_MANAGEMENT_API.GET_INSTANCE_HIERARCHY,
    getInstanceHierarchyPayload
  );
  return response.data;
};

const getAssociations = async (
  getAssosiationsPayload: getAssosiationsPayload
) => {
  const response = await axios.post(
    ASSET_MANAGEMENT_API.GET_ASSOICIATIONS,
    getAssosiationsPayload
  );
  return response.data;
};

const createEdgeRelation = async (
  createEdgeRelationPayload: createEdgeRelationPayload
) => {
  const response = await axios.post(
    ASSET_MANAGEMENT_API.CREATE_EDGE_RELATION,
    createEdgeRelationPayload
  );
  return response.data;
};

const publishInstance = async (publishPayload: publishPayload) => {
  const response = await axios.post(
    ASSET_MANAGEMENT_API.PUBLISH_INSTANCE,
    publishPayload, {
    validateStatus: function (status) {
      return status >= 200 && status < 600; // Treat only 2xx status codes as successful
    }
  });
  return response;
};

const deleteInstance = async (data: IInstanceDeleteEvent) => {
  const response = await axios.post(ASSET_MANAGEMENT_API.DELETE_INSTANCE, data);
  return response.data;
};

export const assetInstanceService = {
  createInstance,
  getAllInstances,
  getInstanceDetails,
  publishInstance,
  getAssociations,
  createEdgeRelation,
  fetchInstanceHierarchy,
  deleteInstance,
};
