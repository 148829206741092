import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAssetModelSliceStates } from "./asset-model.interface";

//initial state
const initialState: IAssetModelSliceStates = {
    publishErrorInstanceName: "",
  };

  const assetModelsSlice = createSlice({
    name: "assetModels",
    initialState: initialState,
    reducers: {
      setpublishErrorInstanceName: (
        state,
        action: PayloadAction<any>
      ) => {
        state.publishErrorInstanceName = action.payload;
      },
    },
  });

  export const {
    setpublishErrorInstanceName
  } = assetModelsSlice.actions;
  export default assetModelsSlice.reducer;
  