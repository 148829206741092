import { IFilePreview } from "modules/property-console/property-console.interface";
import { IInstanceItemDetails } from "store/asset-instance/asset-instance.interface";
import { fileManagemntActions } from "store/file-management/file-management.action";
import { AppThunk } from "..";
import { MESSAGES } from "../../config/default.config";
import { toasterUtils } from "../../utils/toaster.utils";
import {
  IFileSavePayload,
  IPropertySavePayload,
} from "./instance-properties.interface";
import { assetInstancePropertiesService } from "./instance-properties.service";
import {
  setDynamicProperties,
  setProtocols,
} from "./instance-properties.slice";

const saveInstanceProperty = async (
  propertySavePayload: IPropertySavePayload,
  filePreview: IFilePreview,
  instance: IInstanceItemDetails,
  setError: any
) => {
  try {
    const response = await assetInstancePropertiesService.saveInstanceProperty(
      propertySavePayload
    );

    if (response.success) {
      const filePropertyUrls: IFileSavePayload[] = [];
      const fileResponse = response.data.fileResponse as IFileSavePayload[];
      let changedFiles: boolean = false;
      for (const fileItem of fileResponse) {
        const propertyName = fileItem.propertyName;
        const uiFileData = filePreview[propertyName];

        if (!changedFiles) {
          changedFiles = uiFileData.isChanged;
        }

        const uiDeleted = uiFileData.isDeleted;
        const uiRef = uiFileData.ref;
        const uiFile = uiFileData.file.file;

        const cases = {
          delDefault: uiDeleted && !uiRef ? true : false,
          delDefaultNCreateNew: uiDeleted && !uiRef && uiFile ? true : false,
          createNew: !uiDeleted && !uiRef && uiFile ? true : false,
          delFile: uiDeleted && uiRef ? true : false,
          delFileNReplace: uiDeleted && uiRef && uiFile ? true : false,
        };

        let formData: any = new FormData();

        if (cases.delDefaultNCreateNew || cases.createNew) {
          // file upload
          formData.append("file", uiFile?.[0]);
          formData.append("reference", fileItem.reference);
          const uploadFileResponse = await fileManagemntActions.uploadFile(
            formData
          );
          filePropertyUrls.push({
            ...fileItem,
            isChecked: true,
            isDeleted: false,
            reference: uploadFileResponse.reference,
            fileURL: uploadFileResponse.url,
          });
        } else if (cases.delDefault) {
          // remove url from file repsonse
          filePropertyUrls.push({
            ...fileItem,
            isDeleted: false,
            isChecked: true,
            reference: "",
            fileURL: "",
          });
        } else if (cases.delFileNReplace) {
          // replace file
          formData.append("file", uiFile?.[0]);
          formData.append("reference", fileItem.reference);
          const uploadFileResponse = await fileManagemntActions.replaceFile(
            formData
          );
          filePropertyUrls.push({
            ...fileItem,
            isDeleted: false,
            isChecked: true,
            reference: uploadFileResponse.reference,
            fileURL: uploadFileResponse.url,
          });
        } else if (cases.delFile) {
          // delete file
          await fileManagemntActions.deleteFile(uiRef);
          filePropertyUrls.push({
            ...fileItem,
            isChecked: true,
            reference: uiRef,
            fileURL: "",
            isDeleted: true,
          });
        } else {
          filePropertyUrls.push({
            ...fileItem,
            isChecked: true,
            reference: uiRef,
            fileURL: uiFileData.fileURL,
          });
        }
      }

      if (fileResponse.length > 0 && changedFiles) {
        const updateResponse =
          await assetInstancePropertiesService.saveInstanceProperty({
            ...propertySavePayload,
            files: filePropertyUrls,
          });
        if (updateResponse.success) {
          toasterUtils.showSuccess(MESSAGES.PROPERTY_UPDATED_SUCCESS);
          return true;
        } else {
          toasterUtils.showError(MESSAGES.INSTANCE_PROPERTY_UPDATE_FAILED);
          return true;
        }
      } else {
        toasterUtils.showSuccess(MESSAGES.PROPERTY_UPDATED_SUCCESS);
        return true;
      }
    }
  } catch (error: any) {
    if (error?.response?.data?.errorCode === "IS_REQUIRED") {
      let propertyName: any =
        "properties." + error?.response?.data?.data?.propertyName;
      setError(propertyName, {
        type: "custom",
        message: MESSAGES.MANDATORY_FIELD_REQUIRED,
      });
    }
    toasterUtils.showError(MESSAGES.INSTANCE_PROPERTY_UPDATE_FAILED);
  }
};

const saveDynamicProperty = async (dynamicPropertySavePayload: any) => {
  try {
    await assetInstancePropertiesService.saveDynamicProperty(
      dynamicPropertySavePayload
    );
    toasterUtils.showSuccess(MESSAGES.DYNAMIC_PROPERTY_SAVED);
    return true;
  } catch (error) {
    toasterUtils.showSuccess(MESSAGES.DYNAMIC_PROPERTY_ERROR);
    return null;
  }
};

const saveFileInDb = async (
  fileToSave: FormData,
  fileDetailsPayload: IFileSavePayload
) => {
  try {
    let response = await assetInstancePropertiesService.saveFileInDb(
      fileToSave
    );
    if (response.success) {

      fileDetailsPayload.fileURL = response.data.url;
      fileDetailsPayload.reference = response.data.reference;

      await assetInstancePropertiesService.saveFileProperty(fileDetailsPayload);
      toasterUtils.showSuccess(MESSAGES.PROPERTY_UPDATED_SUCCESS);
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

const replaceFileInDb = async (
  fileToReplace: FormData,
  fileDetailsPayload: IFileSavePayload
) => {
  try {
    let response = await assetInstancePropertiesService.replaceFileInDb(
      fileToReplace
    );
    if (response.success) {
      fileDetailsPayload.fileURL = response.data.url;
      await assetInstancePropertiesService.replaceFileProperty(
        fileDetailsPayload
      );
      toasterUtils.showSuccess(MESSAGES.PROPERTY_UPDATED_SUCCESS);
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

const getDeviceList = (deviceListPayload: any): AppThunk => {
  return async (dispatch, getState) => {
    const response = await assetInstancePropertiesService.getDeviceList(
      deviceListPayload
    );
    if (response?.data.result) {
      dispatch(setDynamicProperties(response.data?.result));
    } else {
      console.log("getDeviceListError");
    }
  };
};

const getProtocolList = (): AppThunk => {
  return async (dispatch, getState) => {
    const response = await assetInstancePropertiesService.getProtocolList();
    if (response?.data?.result) {
      dispatch(setProtocols(response?.data?.result));
    } else {
      console.log("getProtocolListError");
    }
  };
};

const downloadFile = async (downloadFilePayload: string) => {
  const response = await assetInstancePropertiesService.downloadFile(
    downloadFilePayload
  );
  if (response.success) {
    toasterUtils.showSuccess(MESSAGES.FILE_DOWNLOAD_SUCCESSFULLY);
  } else {
    toasterUtils.showError(MESSAGES.FILE_DOWNLOAD_FAILED);
  }
  return response;
};
  
export const assetInstancePropertiesActions = {
  saveFileInDb,
  saveInstanceProperty,
  getDeviceList,
  downloadFile,
  replaceFileInDb,
  saveDynamicProperty,
  getProtocolList,
};
