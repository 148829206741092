import axios from "axios";
import { ASSET_UMS_API, KEYCLOAK_CLIENTS, KEYCLOAK_CLIENT_SECRETS, KEYCLOAK_GRANT_TYPES } from "../config/default.config";
import { store } from "store";
import { ITokenDetails } from "store/keycloak/asset-keycloak.interface";
import { setTokens } from "store/keycloak/asset-keycloak.slice";

const initialize = () => {


  // Request interceptor
  axios.interceptors.request.use(
    
    (config: any) => {
      if(localStorage.getItem('auth_token') !== null){
        const token = localStorage.getItem('auth_token') ;
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
      }
      return config;
       
  },
  error => {
      Promise.reject(error)
}
  );

  // Response interceptor
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) { 
      const originalRequest = error.config;
   
      if (error.response.status === 401 && originalRequest.url === ASSET_UMS_API.GET_LOGIN_TOKEN ) {  
          return Promise.reject(error);
      }
   
      if (error.response.status === 401 && !originalRequest._retry ) { 
   
          originalRequest._retry = true;
          const refreshToken = localStorage.getItem('refresh_token')

          const params = new URLSearchParams();
          params.append('client_id', localStorage.getItem('client_id') || "");
          params.append('client_secret', localStorage.getItem('client_secret') || "")
          params.append('grant_type', KEYCLOAK_GRANT_TYPES.REFRESH_TOKENS);
          params.append('refresh_token', refreshToken || "")


          return axios.post(ASSET_UMS_API.GET_LOGIN_TOKEN,params)
              .then(res => {
                  if (res.status === 201) {

                    let auth_token = res.data.access_token;
                    let refresh_token = res.data.refresh_token;
                    const ITokenDetails : ITokenDetails = {
                        auth_token : auth_token,
                        refresh_token : refresh_token,
                        client_id : KEYCLOAK_CLIENTS.ASSET_SUITE,
                        client_secret : KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE,
                        username : localStorage.getItem('username') || "",
                        password : localStorage.getItem('password') || "",
                    }
                    store.dispatch(setTokens(ITokenDetails))
                      localStorage.setItem('auth_token',auth_token);
                      localStorage.setItem('refresh_token',refresh_token);
                      axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth_token;
                      return axios(originalRequest);
                  }
              })
      }
      // return Promise.reject(error);
    }
  );
};

export const interceptiorUtils = {
  initialize,
};
