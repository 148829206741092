import axios from "axios";
import { ASSET_MANAGEMENT_API } from "../../config/default.config";
import { IPropertySavePayload } from "./instance-properties.interface";

const saveInstanceProperty = async (
  propertySavePayload: IPropertySavePayload
) => {
  const response = await axios.post(
    ASSET_MANAGEMENT_API.SAVE_PROPERTY,
    propertySavePayload
  );
  return response.data;
};

const saveDynamicProperty = async (dynamicPropertySavePayload: any) => {
  const response = await axios.post(
    ASSET_MANAGEMENT_API.SAVE_DYNAMIC_PROPERTY,
    dynamicPropertySavePayload
  );
  return response.data;
};

const saveFileInDb = async (fileToSave: FormData) => {
  const response = await axios.post(ASSET_MANAGEMENT_API.SAVE_FILE, fileToSave);
  return response.data;
};

const saveFileProperty = async (propertySavePayload: any) => {
  const response = await axios.post(
    ASSET_MANAGEMENT_API.SAVE_FILE_PROPERTIES,
    propertySavePayload
  );
  return response.data;
};

const replaceFileInDb = async (fileToSave: FormData) => {
  const response = await axios.post(
    ASSET_MANAGEMENT_API.REPLACE_FILE,
    fileToSave
  );
  return response.data;
};

const replaceFileProperty = async (propertySavePayload: any) => {
  const response = await axios.post(
    ASSET_MANAGEMENT_API.REPLACE_FILE_PROPERTIES,
    propertySavePayload
  );
  return response.data;
};

const deleteFileProperty = async (deleteFilePropertyPayload: String) => {
  const response = await axios.delete(
    ASSET_MANAGEMENT_API.DELETE_FILE_PROPERTY + deleteFilePropertyPayload
  );
  return response.data;
};

const downloadFile = async (downloadFilePayload: String) => {
  const response = await axios.get(
    ASSET_MANAGEMENT_API.DOWNLOAD_FILE + downloadFilePayload
  );
  return response.data;
};

const getDeviceList = async (deviceListPayload: any) => {
  const response = await axios.post(
    ASSET_MANAGEMENT_API.GET_ALL_DEVICES,
    deviceListPayload
  );
  return response;
};

const getProtocolList = async () => {
  const response = await axios.get(ASSET_MANAGEMENT_API.GET_ALL_ENUMS);
  return response;
};

export const assetInstancePropertiesService = {
  saveInstanceProperty,
  saveFileInDb,
  deleteFileProperty,
  saveFileProperty,
  replaceFileInDb,
  getDeviceList,
  saveDynamicProperty,
  replaceFileProperty,
  getProtocolList,
  downloadFile,
};
