import DialogProvider from "components/dialogs/DialogProvider";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "store";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from "./components/home.component/home";
import InstanceList from "./components/instance-list.component/instance-list";
import Navbar from "./components/navbar.component/navbar";
import InstanceConsole from "./modules/view-console/view-console";
import { ASSET_SUITE_LOGIN_URL, IFRAME_ID, KEYCLOAK_GRANT_TYPES, SESSION_KEY, SESSION_TYPE } from "config/default.config";
import { encryptUtils } from "utils/encryption.utils";
import { useEffect, useState } from "react";
import { ITokenDetails, IValidateTokenPayload } from "store/keycloak/asset-keycloak.interface";
import { validateToken } from "store/keycloak/asset-keycloak.action";
import { setLogin } from "store/seqr-ops/seqr-ops.slice";

function App() {
  const loading = useAppSelector((state) => state.seqrOps.loadingState.loading);
  const [ready, setReady] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(()=>{
    let tokenDataEncrypted :any = localStorage.getItem(SESSION_KEY.LOCAL_STORAGE_KEY);
    let tokenData = encryptUtils.decryptURL(tokenDataEncrypted);
    if(tokenData !== null){
      setReady(true);
        let data = tokenData;
          const ITokenDetails : ITokenDetails = { 
            auth_token : data.token?.auth_token,
            refresh_token : data.token?.refresh_token,
            client_id : data.token?.client_id,
            client_secret : data.token?.client_secret,
            username : data.token?.username,
            password : data.token?.password,
          }
          const IValidateTokenPayload : IValidateTokenPayload = {
            token : data.token?.auth_token,
            client_id : data.token?.client_id,
            client_secret : data.token?.client_secret,
            username : data.token?.username,
            password : data.token?.password,
            grant_type : KEYCLOAK_GRANT_TYPES.PASSWORD        
          }
          dispatch(setLogin(data?.token?.isLoggedIn))
          dispatch(validateToken(IValidateTokenPayload, data.token?.refresh_token, ITokenDetails));
          // setIsLogged(true);
    }else{
      let rl = encryptUtils.encryptURL(SESSION_KEY.ASSETS_KEY);
      let postMsg = { [SESSION_KEY.REDIRECT_LOCATION_KEY] : rl, type: SESSION_TYPE.USER_LOGIN_TYPE };
      const POST_DOMAIN_DATA = [
        {
          frameId: IFRAME_ID.SUITE_IFRAME_ID,
          url: `${process.env.REACT_APP_SUITE_URL}`,
        }
      ]
      for (let i = 0; i < POST_DOMAIN_DATA.length; i++) {
        console.log("cross domain test in qa assets ::: ", POST_DOMAIN_DATA[i]);
        const iframe = document.getElementById(
          POST_DOMAIN_DATA[i].frameId
          ) as HTMLIFrameElement;
        iframe.contentWindow?.postMessage(postMsg, POST_DOMAIN_DATA[i].url);
      }
      window.location.replace(ASSET_SUITE_LOGIN_URL); 
    }
    },[])

    if (!ready) {
      return null;
    }

    
  return (
    <div className="App">
      <div className="seqrops-navbar-section">
        <Navbar />
      </div>
      <DialogProvider>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route
            path="/model-instance/:details"
            element={<InstanceList />}
          ></Route>
          <Route
            path="/instance/:details"
            element={<InstanceConsole />}
          ></Route>
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      </DialogProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {loading ? 
      <div className="loader-row">
        <div className="loader">
        <div id="ld4">
          <div>
          </div>
          <div>
          </div>
          <div>
          </div>
          <div>
          </div>
        </div>
        </div>
        </div> : null}
    </div>
  );
}

export default App;
