import { IModelTreeBreadcrumbItem } from "components/model-tree.component/model-tree.types";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import closeBtn from "../../assets/images/sequrops_back_icon.svg";
import ModelingTree from "../../components/model-tree.component/model-tree";
import "./tree-console.scss";
import { encryptUtils } from "utils/encryption.utils";
import { assetInstanceActions } from "store/asset-instance/asset-instance.action";
import { Tooltip } from "@mui/material";

function TreeConsole({
  instanceId,
  modelName,
}: {
  instanceId: string;
  modelName: string;
}) {
  const dispatch = useAppDispatch();
  const [breadcrumbs, setBreadcrumbs] = useState<IModelTreeBreadcrumbItem[]>(
    []
  );
  const instanceMap = useAppSelector(
    (state) => state.assetInstances.instanceMap
  );

  const rootInstance: any = useAppSelector(
    (state) => state.assetInstances.rootInstance
  );

  const publishErrorInstanceName: any = useAppSelector(
    (state) => state.assetModels.publishErrorInstanceName
  );

  const navigate = useNavigate();

  //handling close
  const handleClose = () => {
    const data = { modelName };
    const details = encryptUtils.encryptURL(data);
    navigate("/model-instance/" + details);
  };

  useEffect(() => {
    let reqBody = {
      rid: instanceId,
      modelName: modelName,
    };
    if (instanceId) {
      dispatch(assetInstanceActions.getInstanceHierarchy(reqBody));
      setBreadcrumbs([]);
    }
  }, [instanceId]);

  const renderBreadcrumb = () => {
    return (
      <>
        <div className="create_pagination px-0">
          {breadcrumbs.map((item: IModelTreeBreadcrumbItem, index: number) => {
            const bKey = index + "." + (item.vertexName || item.edgeName);
            return (
              <span key={bKey}>
                <b>
                  {item.isEdge
                    ? " / " + item.edgeName + " / "
                    : instanceMap[item.rid]?.name || item.name}
                </b>
              </span>
            );
          })}
        </div>
      </>
    );
  };

  const renderInstanceUpdationError = () => {
    return (
      <>
        <div className="instance-updation-error-section">
          <span>
            <p className="error-message-section">{publishErrorInstanceName}</p>
          </span>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="seqrops-tree-console-wrapper">
        <div className="container-fluid">
          <div className="seqrops-tree-console-title-section row">
            <div className="d-flex align-items-center p-0">
              <button className="seqrops-close-btn p-0 me-3">
                <div>
                  <Tooltip title="back">
                    <span
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <img src={closeBtn} alt="" />
                    </span>
                  </Tooltip>

                </div>
              </button>

              <h5>{rootInstance.instance?.name || ""}</h5>
              {renderBreadcrumb()}
            </div>
          </div>
          {renderInstanceUpdationError()}
          <div className="row seqrops-tree-console-main">
            <ModelingTree
              currentModel={modelName}
              setBreadcrumbs={setBreadcrumbs}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default TreeConsole;
