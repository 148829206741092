export interface IFileValidationResponse {
  isValid: boolean;
  propertyName: string;
}

export interface IValidationRequest {
  name: any;
  propertyName: any;
  propertyValueLen: number;
  propertyValue: any;
  min: any;
  max: any;
  regex: any;
  mandatory: string;
}

export interface IFilePreview {
  [key: string]: {
    fileURL: string;
    rid: string | null;
    ref: string;
    isDeleted: boolean;
    isChanged: boolean;
    file: {
      name: string;
      file: FileList | undefined;
      type: string;
      instanceId: string;
      schemaType: string;
      isMandatory: boolean;
    };
  };
}

export interface IDynamicProperty{
  deviceId: number;
  ipAddress: string;
  port: number;
  propertyName: string;
  protocol: string;
  rid: string | null;
}

export const FILE_TYPES = ["MIMICS", "DOCUMENT", "IMAGE", "ICON"];

export const FILE_MIME_TYPES: any = {
  MIMICS: ["application/json"],
  ICON: ["image/png", "image/gif", "image/jpeg", "image/jpg", "image/svg+xml"],
  DOCUMENT: [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "application/pdf",
    "text/csv",
  ],
  IMAGE: ["image/png", "image/gif", "image/jpeg", "image/jpg"],
};

export const NUMERIC_TYPES = ["INTEGER", "LONG", "SHORT", "DOUBLE", "FLOAT"];

export interface IDynamicPropertyFormItem {
  rid: string | null;
  deviceId: number;
  protocol: string;
  ipAddress: string;
  port: number;
  propertyName: string;
  propertyId: number;
}

export interface IPropertyConsoleForm {
  properties: { [key: string]: any };
  dynamicProperties: IDynamicPropertyFormItem[];
}

export interface IDeviceDetail{
  isModbusTCP : boolean;
  propertyId: number;
}