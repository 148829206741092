import { Button, Modal } from "react-bootstrap";

function DeleleFile(props:any) {

//handling close
const handleClose = () =>{
  props.hide(false);
}
  return (
    <>
   <Modal
          show={props.show}
          onHide={handleClose}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            Confirm to delete  
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={props.deleteFileTrigger}>Success</Button>
          </Modal.Footer>
        </Modal>
    </>
  )
}

export default DeleleFile
