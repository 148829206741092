import { useEffect, useState } from "react";
import ModelListItem from "../model-list.component/model-list-item";
import searchIcon from "../../assets/images/seqrops_search_icon.svg";
import "./home.scss";
import { useForm } from "react-hook-form";
import PaginationWrap from "../pagination/pagination-wrap";
import CreateInstancePopUp from "../../modules/asset-model-crud/create-instance/create-instance";
import { assetModelActions } from "../../store/asset-models/asset-models.action";
import debounce from "debounce";
import seqropsNoModelIcon from "assets/images/seqrops_no_model_found.svg";


interface IModelSearchForm {
  name: string;
}

function Home() {
  const { register, getValues, setValue } = useForm<IModelSearchForm>({
    defaultValues: { name: "" },
  });
  const [apiStatus, setApiStatus] = useState<boolean>(false);
  const [instancePopUpTrigger, setInstancepopUpTrigger] = useState(false);
  const [modelResponse, setModelResponse] = useState([]);
  const [selectedModelName, setSelectedModelName] = useState<string>("");
  const [totalRecords, setTotalRecords] = useState<any>();
  const [paginationPayload, setPaginationPayload] = useState({
    pageNo: 0,
    pageSize: 20,
    sortKey: "",
    sortOrder: "asc",
    totalRecords: 25,
  });

  //handle model click
  const handleModelClick = (modelName: string) => {
    setSelectedModelName(modelName);
    setInstancepopUpTrigger(true);
  };

  //handle search
  const handleSearch = (propertyValues: IModelSearchForm) => {
    if (propertyValues.name !== paginationPayload.sortKey) {
      setPaginationPayload({
        ...paginationPayload,
        sortKey: propertyValues.name,
      });
    }
  };

  useEffect(() => {
    console.log(paginationPayload);
    
    assetModelActions
      .getAllModels(paginationPayload)
      .then((response: any) => {
        setModelResponse(response.data || []);
        setTotalRecords(response.totalRecords);
        setApiStatus(true);
      })
      .catch(() => {
        setModelResponse([]);
        setTotalRecords(0);
        setApiStatus(true);
      });
  }, [paginationPayload]);


  return (
    <>
      <div className="seqrops-home-wrapper ">
        <div className="container">
          <div className="seqrops-home-search-section d-flex">
            <input
              className="form-control"
              type="search"
              placeholder="Search Models"
              {...register("name", {
                pattern: /^\W+$/,
              })}
              onChange={debounce((e: any) => {
                setValue("name", e.target.value);
                handleSearch(getValues());
              }, 500)}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  handleSearch(getValues());
                }
              }}
            />
            <button
              className="seqropsSearchBtn"
              onClick={() => {
                handleSearch(getValues());
              }}
            >
              <img src={searchIcon} alt="seqrops search icon" />
            </button>
          </div>
          <div className="d-flex seqrops-home-model-list-section flex-wrap">
            {modelResponse.map((model: any) => (
              <div 
                className="seqrops-home-model-list my-2 px-2 qq"
                key={model.vertexName}
              > 
                <ModelListItem
                  key={model.rid}
                  modelName={model.vertexName}
                  modelDescription={model.description}
                  modelIcon={model.icon}
                  modelIsEquipment={model.isEquipment}
                  modelClickTrigger={handleModelClick}
                  instanceCount={model.instanceCount}
                 
                />
              </div>
            
            ))
            }
            
            {apiStatus && !totalRecords ? (
              <>
                <div className="seqrops-inital-page-wrapper">
                  <div className="container">
                    <div className="seqrops-no-model-image-section">
                      <img
                        src={seqropsNoModelIcon}
                        alt="seqrops no-model icon"
                      />
                    </div>
                    <div className="seqrops-no-model-image-text-section">
                      No Models Found
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <PaginationWrap
            paginationPayload={paginationPayload}
            setPaginationPayloadTrigger={setPaginationPayload}
            totalRecords={totalRecords}
          />
          <CreateInstancePopUp
            instancePopUp={instancePopUpTrigger}
            instancePopUpTrigger={setInstancepopUpTrigger}
            modelName={selectedModelName}
          ></CreateInstancePopUp>
        </div>
      </div>
    </>
  );
}

export default Home;
