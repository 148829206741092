import DeleteConfirmationDialog from "components/dialogs/delete-confirmation.dialog";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setpublishErrorInstanceName } from "store/asset-models/asset-models.slice";
import CreateAssosiation from "../../modules/asset-model-crud/create-association/create-association";
import { useAppDispatch, useAppSelector } from "../../store";
import { assetInstanceActions } from "../../store/asset-instance/asset-instance.action";
import {
  EdgeRelationPayload,
  IInstanceItemDetails,
} from "../../store/asset-instance/asset-instance.interface";
import {
  setInstanceHierarchy,
  setInstanceStaticProperties,
} from "../../store/asset-instance/asset-instance.slice";
import "./model-tree.scss";
import {
  IModelTreeActions,
  IModelTreeBreadcrumbItem,
  IModelTreeMap,
  IInstanceDeleteEvent,
} from "./model-tree.types";
import TreeList from "./sub.components/tree-list";
import { encryptUtils } from "utils/encryption.utils";

function ModelingTree(props: {
  currentModel: string | undefined;
  setBreadcrumbs: (breadcrumbs: IModelTreeBreadcrumbItem[]) => void;
}) {
  const confirmationPopup = DeleteConfirmationDialog();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const instanceProperties: any = useAppSelector(
    (state) => state.assetInstances.properties
  );

  const modelHierarchyList = useAppSelector(
    (state) => state.assetInstances.instanceHierarchy
  );

  const [tree, setTree] = useState<IInstanceItemDetails[]>([]);
  const [toggleMap, setToggleMap] = useState<IModelTreeMap>({});
  const [actionMap, setActionMap] = useState<IModelTreeMap>({});
  const { currentModel,modelName } = useParams();
  const [assosiationPopUp, setAssosiationPopUp] = useState(false);
  const [isAssociation, setIsAssociation] = useState(false);
  const [assosiationPayload, setAssosiationPayload] =
    useState<EdgeRelationPayload>({
      customKey: "",
      modelName: "",
      id: "",
      edgeName: "",
      targetId: "",
      vertexName: "",
      sourceModelName: "",
      isAssociation: false,
    });
  const [breadcrumbs, setBreadcrumbs] = useState<IModelTreeBreadcrumbItem[]>(
    []
  );

  useEffect(() => {
    setTree([...modelHierarchyList]);
  }, [modelHierarchyList]);

  useEffect(() => {
    if (tree.length > 0 && breadcrumbs[0]?.rid !== tree[0]?.rid) {
      setBreadcrumbs([
        {
          rid: tree[0]?.rid || "",
          name: tree[0]?.instance?.name || "",
          edgeName: tree[0]?.edgeName || "",
          vertexName: tree[0]?.vertexName || "",
          isEdge: tree[0]?.isEdge || false,
        },
      ]);

      props.setBreadcrumbs([
        {
          rid: tree[0]?.rid || "",
          name: tree[0]?.instance?.name || "",
          edgeName: tree[0]?.edgeName || "",
          vertexName: tree[0]?.vertexName || "",
          isEdge: tree[0]?.isEdge || false,
        },
      ]);
    }
  }, [tree, breadcrumbs]);

  const assosiationToggle = (
    key: boolean,
    sourceModelId: string,
    edgeName: string,
    vertexName: string,
    sourceModelName: string,
    isAssociation: boolean,
    customKey: string
  ) => {
     //restoring publish error
     let message : string = "";
     dispatch(setpublishErrorInstanceName(message));

    setAssosiationPayload({
      customKey,
      modelName: currentModel,
      id: sourceModelId,
      edgeName: edgeName,
      targetId: "",
      vertexName: vertexName,
      sourceModelName: sourceModelName,
      isAssociation: false,
    });

    setAssosiationPopUp(key);
    setIsAssociation(isAssociation);
  };

  const toggleOpen = (key: string) => {
    toggleMap[key] = !toggleMap[key];
    setToggleMap({ ...toggleMap });
  };

  const toggleAction = (key: string) => {
    const am = { [key]: true };
    setActionMap({ ...am });
  };

  function formatData(
    data: IInstanceItemDetails[],
    keyIndexes: number[],
    subModel: any[]
  ) {
    if (keyIndexes.length === 0) return;
    data.forEach((item: IInstanceItemDetails, index: number) => {
      if (keyIndexes.length === 1) {
        if (index === keyIndexes[0]) {
          item.edges = subModel;
          return item;
        }
      } else if (keyIndexes[keyIndexes.length - 1] === index) {
        keyIndexes.pop();
        return formatData(item.edges, keyIndexes, subModel);
      }
    });
  }

  function getBreadcumbData(
    data: IInstanceItemDetails[],
    breadcrumbItems: IModelTreeBreadcrumbItem[],
    keyIndexes: number[]
  ) {
    if (keyIndexes.length === 0) return;
    data.forEach((item: IInstanceItemDetails, index: number) => {
      if (keyIndexes.length === 1) {
        if (index === keyIndexes[0]) {
          breadcrumbItems.push({
            rid: item.rid || "",
            name: item.instance?.name || "",
            edgeName: item.edgeName || "",
            vertexName: item.vertexName || "",
            isEdge: item.isEdge || false,
          });
          return item;
        }
      } else if (keyIndexes[keyIndexes.length - 1] === index) {
        breadcrumbItems.push({
          rid: item.rid || "",
          name: item.instance?.name || "",
          edgeName: item.edgeName || "",
          vertexName: item.vertexName || "",
          isEdge: item.isEdge || false,
        });
        keyIndexes.pop();
        return getBreadcumbData(item.edges, breadcrumbItems, keyIndexes);
      }
    });
  }

  const getSubModel = async (id: string, key: string, model: string) => {
    const keys = key.split("tree-root-")[1].split("-")?.reverse();
    let keyIndexes = keys?.map(Number) || [];

    const treeTmp = JSON.parse(JSON.stringify(tree));
    const reqBody = { rid: id, modelName: model };
    const subResponseData: any =
      await assetInstanceActions.getSubInstanceHierarchy(reqBody);

    formatData(treeTmp, keyIndexes, subResponseData?.data?.[0]?.edges || []);
    dispatch(
      setInstanceStaticProperties({
        ...instanceProperties,
        ...subResponseData?.properties,
      })
    );

    dispatch(setInstanceHierarchy(treeTmp));
  };

  const updateBreadcrumb = (key: string) => {
    const keys = key.split("tree-root-")[1].split("-")?.reverse();
    let keyIndexes = keys?.map(Number) || [];

    const treeTmp = JSON.parse(JSON.stringify(tree));
    const breadcrumbItems: IModelTreeBreadcrumbItem[] = [];

    getBreadcumbData(treeTmp, breadcrumbItems, keyIndexes);
    setBreadcrumbs([...breadcrumbItems]);
    props.setBreadcrumbs([...breadcrumbItems]);
  };

  const deleteInstance = async (
    customKey: string,
    data: IInstanceDeleteEvent
  ) => {
    const deleteResponse = await assetInstanceActions.deleteInstance(data);
    if (deleteResponse) {
      if(data.isRootNode){
        const urlData = { modelName: data.sourceModel };       
        const details = encryptUtils.encryptURL(urlData);
        navigate("/model-instance/" + details);
      }
      confirmationPopup.closeDialog();
      getSubModel(data.sourceId, customKey, data.sourceModel);
    }
  };

  const handleInstanceDelete = (
    customKey: string,
    data: IInstanceDeleteEvent
  ) => {
    confirmationPopup.onOpenDialog({
      title: "Delete Confirmation",
      message: "Are you sure want to delete the instance?",
      cancelBtn: "Cancel",
      successBtn: "Success",
      handleClose: async () => {
        const lastIndex = customKey.slice(0, customKey.lastIndexOf("-"));
        await deleteInstance(
          lastIndex.slice(0, lastIndex.lastIndexOf("-")),
          data
        );
      },
    });
  };

  const actions: IModelTreeActions = {
    toggleOpen,
    toggleAction,
    assosiationToggle,
    getSubModel,
    deleteInstance: handleInstanceDelete,
    setBreadcrumb: updateBreadcrumb,
  };
  return (
    <>
      <TreeList
        customKey={"tree-root"}
        tree={tree}
        toggleMap={toggleMap}
        actionMap={actionMap}
        actions={actions}
      />
      <CreateAssosiation
        getSubModel={getSubModel}
        assosiationPayload={assosiationPayload}
        isAssociation={isAssociation}
        assosiationPopUp={assosiationPopUp}
        setAssosiationPopUp={setAssosiationPopUp}
      />
    </>
  );
}
export default ModelingTree;
