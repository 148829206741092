import { IInstanceItemDetails } from "store/asset-instance/asset-instance.interface";
import { IModelTree } from "../model-tree.types";
import TreeItem from "./tree-item";

function TreeList({
  customKey,
  tree,
  actions,
  toggleMap,
  actionMap,
}: IModelTree) {
  return (
    <ul className={customKey === "tree-root" ? "ms-0 px-0" : "ms-0"}>
      {tree.map((child: IInstanceItemDetails, index: number) => {
        const indexStr = "-" + index;
        return (
          <TreeItem
            key={customKey + "" + indexStr}
            customKey={customKey + "-" + index}
            item={child}
            toggleMap={toggleMap}
            actions={actions}
            actionMap={actionMap}
          />
        );
      })}
    </ul>
  );
}
export default TreeList;
