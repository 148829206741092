import { toasterUtils } from "utils/toaster.utils";
import { getAllModelsPayload } from "./asset-model.interface";
import { assetModelService } from "./asset-models.service";
import { ERROR_CODES, MESSAGES } from "config/default.config";

const getAllModels = async (getAllModelsPayload : getAllModelsPayload) => {

    try {
      const response = await assetModelService.fetchAllModels(getAllModelsPayload);
      
      if (response.success) {
        return response.data;
      }else{
        return 0;
      }
    } catch (error: any) {
      if(error.response.data.errorCode === ERROR_CODES.SCHEMA_DB_NOT_FOUND){
        toasterUtils.showError(MESSAGES.SCHEMA_DB_NOT_FOUND_MESSAGE);
      }
      return 0;
    }
  };


  export const assetModelActions = {
    getAllModels
  };