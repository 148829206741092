import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { interceptiorUtils } from "../utils/interceptor.utils";
import assetInstanceSlice from "./asset-instance/asset-instance.slice";
import assetModelsSlice from "./asset-models/asset-models.slice";
import instancePropertiesSlice from "./instance-properties/instance-properties.slice";
import seqrOpsSlice from "./seqr-ops/seqr-ops.slice";
import assetKeycloakSlice from "./keycloak/asset-keycloak.slice";

export const store = configureStore({
  reducer: {
    seqrOps: seqrOpsSlice,
    assetInstances: assetInstanceSlice,
    instanceProperties: instancePropertiesSlice,
    assetModels: assetModelsSlice,
    assetKeycloak: assetKeycloakSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;

interceptiorUtils.initialize();
