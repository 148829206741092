import closeBtn from "assets/images/seqrops_close_icon.svg";
import "./create-instance.scss";
import { Button, Modal } from "react-bootstrap";
import { FieldErrors, useForm } from "react-hook-form";
import { assetInstanceActions } from "store/asset-instance/asset-instance.action";
import { MESSAGES } from "config/default.config";
import { ICreateInstanceForm } from "./create-instance.interface";
import { useAppDispatch, useAppSelector } from "store";
import { seqrOpsActions } from "store/seqr-ops/seqr-ops.action";
import { setpublishErrorInstanceName } from "store/asset-models/asset-models.slice";

function CreateInstancePopUp({
  instancePopUp,
  instancePopUpTrigger,
  modelName,
}: {
  instancePopUp: boolean;
  instancePopUpTrigger: (state: boolean) => void;
  modelName: string;
}) {
  const loading = useAppSelector((state) => state.seqrOps.loadingState.loading);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
    formState,
  } = useForm<ICreateInstanceForm>({
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const errors: FieldErrors<ICreateInstanceForm> = formState.errors;

  const setSuccess = (state: boolean = true) => {
    if (state) {
      dispatch(seqrOpsActions.setLoader(false));
      clearErrors();
      reset();
      instancePopUpTrigger(false);
    } else {
      dispatch(seqrOpsActions.setLoader(false));
    }
  };

  const errorWrap = (errorMsg: string | undefined) => {
    if (!errorMsg) return null;
    return <p className="error-message-section">{errorMsg}</p>;
  };

  //handling save
  const handleSave = async (propertyValues: any) => {
    //restoring publish error
    let message : string = "";
    dispatch(setpublishErrorInstanceName(message));

    dispatch(seqrOpsActions.setLoader(true));
    propertyValues["modelName"] = modelName;
    await assetInstanceActions.createInstance(
      propertyValues,
      setError,
      setSuccess
    );
  };

  return (
    <>
      <Modal
        show={instancePopUp}
        onHide={setSuccess}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>Create {modelName}</Modal.Title>
          <button
            className="seqrops-relationship-popup-close-btn"
            disabled={loading}
            onClick={() => {
              setSuccess();
            }}
          >
            <img src={closeBtn} alt="" />
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="seqrops-create-instance-form-section">
            <div className="seqrops-create-instance-name-section">
              <label className="form-label" htmlFor="name">
                Name
              </label>
              <input
                className="form-control"
                type="text"
                id="name"
                maxLength={50}
                autoFocus
                {...register("name", {
                  required: MESSAGES.INSTANCE_NAME_REQUIRED,
                  pattern: {
                    value: /^\w*$/,
                    message: MESSAGES.INSTANCE_NAME_PATTERN,
                  },
                })}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue("name", e.target.value.replace(/\W/g, ""));
                  clearErrors("name");
                }}
              />
               <label className="form-label" htmlFor="name">
               {errorWrap(errors?.name?.message)}
              </label>
         
            </div>
            <div className="seqrops-create-instance-description-section mt-2">
              <label className="form-label" htmlFor="description">
                Description
              </label>
              <textarea
                className="form-control"
                id="description"
                minLength={3}
                maxLength={250}
                rows={5}
                {...register("description", {
                  // required: MESSAGES.INSTANCE_DESC_REQUIRED,
                })}
                onChange={() => {
                  clearErrors("description");
                }}
              ></textarea>
                  <label className="form-label" htmlFor="description">
                  {errorWrap(errors?.description?.message)}
              </label>
             
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="sequrops_btn_primary"
            onClick={handleSubmit(handleSave)}
            disabled={loading}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateInstancePopUp;
