import { fileManagemntService } from "./file-management.service";

const uploadFile = async (data: FormData) => {
  try {
    const response = await fileManagemntService.uploadFile(data);
    if (response.success) {
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

const replaceFile = async (data: FormData) => {
  try {
    const response = await fileManagemntService.replaceFile(data);
    if (response.success) {
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

const deleteFile = async (reference: string) => {
  try {
    const response = await fileManagemntService.deleteFile(reference);
    if (response.success) {
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fileManagemntActions = {
  uploadFile,
  replaceFile,
  deleteFile,
};
