import collapseClosedIcon from "../../../assets/images/collapse-closed.png";
import collapseOpenedIcon from "../../../assets/images/collapse-opened.png";
import aggrigationIcon from "assets/images/seqrops_relation_Icon.svg";
import assosiationIcon from "assets/images/assosiation_icon.svg";
import submodelIcon from "../../../assets/images/seqrops_sub_model_icon.svg";
import { IModelTreeItem } from "../model-tree.types";
import TreeList from "./tree-list";
import NodeActionMenu from "./node-action-menu";
import { setInstance } from "../../../store/asset-instance/asset-instance.slice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { setpublishErrorInstanceName } from "store/asset-models/asset-models.slice";

function TreeItem({
  customKey,
  item,
  actions,
  toggleMap,
  actionMap,
}: IModelTreeItem) {
  const isRootNode = customKey === "tree-root-0" ? true : false;
  const { toggleOpen, getSubModel, setBreadcrumb } = actions;
  const instanceMap = useAppSelector(
    (state) => state.assetInstances.instanceMap
  );
  const dispatch = useDispatch();

  //handling double click
  // const handleDoubleClick = () => {
  //   setIsEdit(true);
  //   window.addEventListener("keydown", handleEsc);
  //   window.addEventListener("focusout", handleFocusOut);
  // };

  //handling focus out
  // const handleFocusOut = () => {
  //   setIsEdit(false);
  // };

  //handling node click
  const handleNodeClick = () => {
    //restoring publish error
    let message : string = "";
    dispatch(setpublishErrorInstanceName(message));

    if (!item.isEdge) {
      dispatch(
        setInstance({
          ...item,
          edges: [],
          instance: { ...item.instance, ...instanceMap[item.rid] },
        })
      );
    }
    setBreadcrumb(customKey);
  };

  //handling esc keypress
  // const handleEsc = (event: any) => {
  //   if (event.keyCode === 27) {
  //     setIsEdit(false);
  //   }
  // };

  return (
    <li className="ps-0 pt-2" style={{ listStyle: "none" }}>
      <span
        style={{ border: "1px solid #e0dada" }}
        className="d_tree_main"
        onClick={(e) => {
          e.stopPropagation();
          handleNodeClick();
        }}
      >
        {item.hasEdge && (
          <span
            className="d_tree_collapse"
            style={{ marginRight: 5 }}
            onClick={() => {
              toggleOpen(customKey);
            }}
          >
            {toggleMap[customKey] ? (
              <>
                <span>
                  <img
                    src={collapseClosedIcon}
                    width={25}
                    height={25}
                    alt={"Close"}
                  />
                </span>
              </>
            ) : (
              <>
                <span
                  onClick={() => {
                    if (item.hasEdge && item.edges.length === 0) {
                      getSubModel(item.rid, customKey, item.vertexName);
                    }
                  }}
                >
                  <img
                    src={collapseOpenedIcon}
                    width={25}
                    height={25}
                    alt={"Open"}
                  />
                </span>
              </>
            )}
          </span>
        )}
        <span className="d_tree_inner w-100" style={{ color: "green" }}>
          {item.isEdge ? (
            <span className="seqrops_add_icon">
               {!item.isAssosiation ? 
              <>
              <img
                src={aggrigationIcon}
                width={25}
                height={25}
                alt={item.edgeName}
              />
              </> : 
              <>
              <img
                src={assosiationIcon}
                width={25}
                height={25}
                alt={item.edgeName}
              />
              </>}
              <span>{item.edgeName}</span>

              <NodeActionMenu
                item={item}
                isRootNode={false}
                actions={actions}
                customKey={customKey}
              />
            </span>
          ) : (
            <span>
              <img
                src={submodelIcon}
                width={25}
                height={25}
                alt={item.vertexName}
              />
              <span>
                {instanceMap[item.rid]?.name ||
                  item.instance?.name ||
                  item.vertexName}
              </span>
              <NodeActionMenu
                item={item}
                isRootNode={isRootNode}
                actions={actions}
                customKey={customKey}
              />
            </span>
          )}
        </span>
      </span>
      {toggleMap[customKey] && (
        <TreeList
          customKey={customKey}
          tree={item.edges}
          actions={actions}
          toggleMap={toggleMap}
          actionMap={actionMap}
        />
      )}
    </li>
  );
}

export default TreeItem;
